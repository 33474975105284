import { useState, useEffect, lazy, Suspense } from 'react';
import { isSaved, isCompleted, isVerified, isItemInList, getItemsInListOfType, getItemInList } from '@alltrails/modules/Lists/listUtils';
import { getLogListItemAddedData } from '@alltrails/modules/Lists/listAmplitudeHelpers';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import { PageStrings } from '@alltrails/shared/utils/constants/pageStringHelpers';
import { usersContentPrivacyPolicy } from 'utils/UserUtils';
import { LISTS as PRIVACY_POLICY_LISTS, VISIBILITY_FOLLOWERS_ONLY, VISIBILITY_PRIVATE } from 'utils/privacy_policy_helpers';
import { modalRoadblock } from '@alltrails/shared/utils/modalFunnelUtils';
import CustomProvider from 'components/CustomProvider';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import Toast from '@alltrails/shared/denali/components/Toast';
import useUser from 'hooks/useUser';
import useExperiment from 'hooks/useExperiment';
import PortalModal from 'components/shared/PortalModal';
import { SearchInsightsContext } from '../components/SearchInsightsProvider';
import listReducer, { toggleListItem, addCreatedList, LISTS, setListsAndListItems, updateListOrder, updateListItems } from '../ducks/lists';
import useThunkReducer from './useThunkReducer';
import '@alltrails/modules/Lists/listsCss.css';

const SaveToListModal = lazy(() => import('@alltrails/modules/Lists/SaveToListModal'));
const ListPrivacyCheckModal = lazy(() => import('@alltrails/modules/Lists/ListPrivacyCheckModal'));

const useListItems = (initialState, page) => {
  const user = useUser();
  const languageRegionCode = useLanguageRegionCode();
  const [showModal, toggleShowModal] = useState(false);
  const [activeItem, setActiveItem] = useState({ type: null, id: null, objectID: null, contentPrivacy: null });
  const [state, dispatch] = useThunkReducer(listReducer, LISTS, { ...initialState, newLists: {} });
  const [riverAnalyticsData, setRiverAnalyticsData] = useState(null);
  const [trailIndex, setTrailIndex] = useState(null);
  const [detailedCardLocation, setDetailedCardLocation] = useState(null);
  const [listPrivacyCheckModalData, setListPrivacyCheckModalData] = useState({ isOpen: false, listId: undefined });
  const [listToastData, setListToastData] = useState({
    isVisible: false,
    listId: undefined,
    listTitle: undefined,
    id: undefined,
    type: undefined,
    belongsToCurrentUser: undefined
  });
  const experiment = useExperiment('web-community-collaborative-lists');
  const collaborativeListsFeatureFlag = experiment?.value === 'on';

  useEffect(() => {
    /**
     * this ensures that we lock the screen in the monolith so users
     * cant scroll while the modal is active
     *
     */
    const { classList } = document.querySelector('html');
    if (showModal) {
      classList.add('modalOpen');
    } else {
      classList.remove('modalOpen');
    }
  }, [showModal]);

  useEffect(() => {
    /**
     * this ensures that we lock the screen in the monolith so users
     * cant scroll while the modal is active
     *
     */
    const { classList } = document.querySelector('html');
    if (listPrivacyCheckModalData.isOpen) {
      classList.add('modalOpen');
    } else {
      classList.remove('modalOpen');
    }
  }, [listPrivacyCheckModalData.isOpen]);

  useEffect(() => {
    /**
     * this ensures that the removed list item toast is visible
     * for 3 seconds
     *
     */
    const timeout = setTimeout(() => {
      if (listToastData.isVisible) {
        setListToastData({ isVisible: false });
      }
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [listToastData, setListToastData]);
  /**
   * handleModalFavoriteClick
   *
   * Triggers the request when a user adds a piece of content to a custom list.
   *
   * @param {number} listId
   * @param {object} amplitudeAnalyticsData
   *
   */
  const handleModalFavoriteClick = conversionTrackingFunction => (listId, amplitudeAnalyticsData) => {
    const isListCollaborative = initialState.lists?.find(list => list.id === listId)?.isCollaborative;
    if (
      (activeItem.contentPrivacy === VISIBILITY_PRIVATE || activeItem.contentPrivacy === VISIBILITY_FOLLOWERS_ONLY) &&
      collaborativeListsFeatureFlag &&
      isListCollaborative
    ) {
      setListPrivacyCheckModalData({ isOpen: true, listId });
    } else {
      dispatch(toggleListItem(user.id, listId, activeItem.type, activeItem.id, amplitudeAnalyticsData));

      const isAlreadyInFavorites = isSaved(state.listItems, activeItem.type, activeItem.id);
      if (activeItem.objectID && !isAlreadyInFavorites) {
        conversionTrackingFunction(`${activeItem.objectID}`);
      }
    }
  };

  /**
   * handleModalListCreated
   *
   * adds a new list when a user creates one from the modal.
   */
  const handleModalListCreated = newList => {
    dispatch(addCreatedList({ ...newList, title: newList.name }));
  };

  /**
   * closeModal
   *
   * closes the modal and resets the type and id
   */
  const closeModal = () => {
    toggleShowModal(false);
    setActiveItem({ type: null, id: null, objectID: null, contentPrivacy: null });
    setRiverAnalyticsData(null);
    setTrailIndex(null);
    setDetailedCardLocation(null);
  };

  const renderCollabListPrivacyModal = () => {
    if (!listPrivacyCheckModalData.isOpen) {
      return null;
    }

    return (
      <PortalModal isOpen={listPrivacyCheckModalData.isOpen}>
        <Suspense fallback={null}>
          <CustomProvider>
            <ListPrivacyCheckModal
              closeModal={() => setListPrivacyCheckModalData({ isOpen: false })}
              privacy={activeItem.contentPrivacy}
              type={activeItem.type}
              listId={listPrivacyCheckModalData.listId}
            />
          </CustomProvider>
        </Suspense>
      </PortalModal>
    );
  };

  const renderModal = () => {
    if (!showModal) {
      return null;
    }

    return (
      <PortalModal isOpen={showModal}>
        <Suspense fallback={null}>
          <SearchInsightsContext.Consumer>
            {({ logConversionEvent }) => (
              <CustomProvider>
                <SaveToListModal
                  analyticsInfo={{ detailedCardLocation, page, riverAnalyticsData, trailIndex }}
                  closeModal={closeModal}
                  initialPrivacyPolicy={usersContentPrivacyPolicy(user, PRIVACY_POLICY_LISTS)}
                  itemId={activeItem.id}
                  itemType={activeItem.type}
                  lists={state.lists}
                  listItems={state.listItems}
                  onListSelectionChange={handleModalFavoriteClick(logConversionEvent)}
                  onListCreate={handleModalListCreated}
                  collaborativeListsFeatureFlag={collaborativeListsFeatureFlag}
                />
              </CustomProvider>
            )}
          </SearchInsightsContext.Consumer>
        </Suspense>
      </PortalModal>
    );
  };

  /**
   * handleUndoFavorites
   *
   * Used to undo an action to remove/add item from favorites
   *
   */
  const handleUndoFavorites = () => {
    dispatch(
      toggleListItem(
        user.id,
        listToastData.listId,
        listToastData.type,
        listToastData.id,
        getLogListItemAddedData(listToastData.listId, listToastData.id, listToastData.type),
        listToastData.belongsToCurrentUser,
        setListToastData,
        listToastData.listTitle
      )
    );
  };

  const renderListToast = () => {
    if (listToastData.isVisible) {
      const isSaved = listToastData.prefixText === 'saved';
      return (
        <Toast
          message={
            isSaved ? (
              <FormattedMessage defaultMessage="Saved to {listTitle}" values={{ listTitle: listToastData.listTitle }} />
            ) : (
              <FormattedMessage defaultMessage="Removed from {listTitle}" values={{ listTitle: listToastData.listTitle }} />
            )
          }
          type="success"
          testId="list_toast"
          position="alternative"
          actionText={<FormattedMessage defaultMessage="Undo" />}
          action={handleUndoFavorites}
        />
      );
    }
    return null;
  };

  /**
   * handleFavoriteClick
   *
   * Used to trigger the modal and set the active item. After this is triggered, the modal should open
   * and there is an active item in which users can add items to a list.
   *
   * @param {{ type: 'map' | 'track' | 'trail', id: number, objectId: string, contentPrivacy?: string, riverAnalyticsData?: object,
   * index?: number, detailedCardLocation?: CardLocation, listId?: number, listTitle?: string, belongsToCurrentUser?: boolean; }}
   * @returns
   */
  const handleFavoriteClick = ({
    type,
    id,
    objectId,
    contentPrivacy,
    riverAnalyticsData,
    index,
    detailedCardLocation,
    listId,
    listTitle,
    belongsToCurrentUser
  }) => {
    if (!user) {
      modalRoadblock('signup', `${type}-resultcard-favorite`, window.location.pathname, languageRegionCode);
      return;
    }

    if (
      collaborativeListsFeatureFlag &&
      (page === PageStrings.EXPLORE_CUSTOM_PAGE || page === PageStrings.EXPLORE_FAVORITE_PAGE) &&
      belongsToCurrentUser
    ) {
      dispatch(
        toggleListItem(user.id, listId, type, id, getLogListItemAddedData(listId, id, type), belongsToCurrentUser, setListToastData, listTitle)
      );
      return;
    }
    setActiveItem({ type, id, objectID: objectId, contentPrivacy });
    setRiverAnalyticsData(riverAnalyticsData);
    setTrailIndex(index);
    setDetailedCardLocation(detailedCardLocation);
    toggleShowModal(true);
  };

  /**
   * handleToggleListItem
   *
   * This method is used to add or remove an item from a list without having to go through the modal flow.
   * Used currently in search_app to add and remove completed items, without use of the modal.
   *
   * @param {number} listId
   * @param {string} type
   * @param {number} objectId
   * @param {number} userId
   *
   */
  const handleToggleListItem = (listId, type, objectId, userId = null) => {
    if (!user) {
      modalRoadblock('signup', `${type}-resultcard-favorite`, window.location.pathname, languageRegionCode);
      return;
    }

    dispatch(toggleListItem(userId || user.id, listId, type, objectId, getLogListItemAddedData(listId, objectId, type)));
  };

  /**
   * This hook returns a grouping of different methods to interact with a favorite list, that
   * pull from the list_helpers util to make a number of different calls.
   *
   * it also renders passes a rendered version of the ListModal in which a user can add different pieces of
   * content to their lists.
   *
   */

  return {
    listMethods: {
      handleFavoriteClick,
      isComplete: (type, id) => isCompleted(state.belongsToCurrentUser ? state.userListItems : state.listItems, type, id),
      isFavorite: (type, id) => isSaved(state.listItems, type, id, state.listItems),
      isVerified: (type, id) => isVerified(state.belongsToCurrentUser ? state.userListItems : state.listItems, type, id),
      hasItemsInList: (listId, listItems) => (listItems && listItems[listId] ? Object.keys(listItems[listId]).length > 0 : false),
      toggleListItem: (listId, type, objectId, userId = null) => handleToggleListItem(listId, type, objectId, userId),
      itemsInListOfType: (listId, type) => getItemsInListOfType(state.listItems, listId, type),
      getListItemInList: (listId, type, objectId, listItems) => getItemInList(listItems, listId, type, objectId),
      isItemInList: (type, id, listId) => isItemInList(state.listItems, listId, type, id),
      setActiveItem,
      setListsAndListItems: ({ lists, listItems }) => dispatch(setListsAndListItems({ lists, listItems })),
      toggleShowModal,
      updateListOrder: (listItems, listId) => dispatch(updateListOrder(listItems, listId)),
      updateListItems: listItems => dispatch(updateListItems(listItems))
    },
    listItems: state.listItems,
    lists: state.lists,
    userListItems: state.userListItems,
    latestListAction: state.latestListAction,
    renderModal,
    renderCollabListPrivacyModal,
    renderListToast,
    listToastData,
    setListToastData
  };
};

export default useListItems;
